import React from "react";
import styles from "../styles/Mission.module.css"

export default function Mission() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <p className={styles.message}>不毛さを減らし、すべての開発者を<span className="text-green-500 font-bold">幸せ</span>にする</p>
        <img className="w-1/2 mx-auto block" src="/mission.jpg" alt="Craftsman Software"/>
        <p className="text-center pt-10">エンジニアの仕事から退屈な”作業“を減らし<br/>開発をもっと<span className="text-green-500 font-bold">クリエイティブ</span>で<span className="text-green-500 font-bold">楽しい</span>ものにすることを目標にしている<br/>エンジニアによるエンジニアのための会社です</p>
      </div>
    </div>
  );
}
