import React from "react";
import styles from "../styles/Activities.module.css";

export default function Activities() {
  return (
    <div className={styles.wrap}>
      <div className={styles.inner}>
        <h2 className={styles.title}>コミュニティ活動</h2>
        <section className="container pt-4 mx-auto">
          <div className="grid gap-6 mb-8 md:grid-cols-1 lg:grid-cols-2">
            <a
              href="https://yyts.connpass.com/"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="yyts"
                  className="w-20 rounded-md border-2 border-gray-300"
                  src="/yy-ts.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">YYTypeScript</h4>
                  <p className="text-gray-800 mt-2 text-sm">
                    TypeScriptについて、雑に・ゆるく・ワイワイ話しながらTypeScripter同士の交流を深める。毎週金曜日に開催。現在
                    <a
                      className="text-green-500 underline font-bold"
                      href="https://book.yyts.org/"
                      target="_blank"
                      rel="norefferrer"
                    >
                      サバイバルTypeScript
                    </a>
                    を執筆中
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://qiita.com/organizations/yyphp"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="yyphp"
                  className="w-20 rounded-md border-2 border-gray-300"
                  src="/yy-php.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">YYPHP</h4>
                  <p className="text-gray-800 mt-2 text-sm">
                    PHPについて、雑に・ゆるく・ワイワイ話し合うPHPerの交流を深めるイベント。2019年にYYTypeScriptへバトンタッチ。
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://www.youtube.com/@craftsmansoftware"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="youtube"
                  className="w-20 rounded-md border-2 border-gray-300 h-100"
                  src="/youtube_logo.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">
                    YouTube
                  </h4>
                  <p className="text-gray-800 mt-2 text-sm">
                    Live配信の切り抜きやプログラミングの小ネタを発信。
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://youtube.com/playlist?list=PLpOeTEye3Bg74DwJw7vTIcvzmmf1Jlw_D"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="craftsman-talk"
                  className="w-20 rounded-md border-2 border-gray-300 h-100"
                  src="/craftsman-talk.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">
                    Craftsman Software Talk
                  </h4>
                  <p className="text-gray-800 mt-2 text-sm">
                    Craftsman SoftwareメンバーによるYouTube
                    Live配信です。経営からプログラミングの話まで話題は盛りだくさん。
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://qiita.com/organizations/craftsman_software"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="qiita"
                  className="w-20 rounded-md border-2 border-gray-300"
                  src="/qiita.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">Qiita</h4>
                  <p className="text-gray-800 mt-2 text-sm">
                    Craftsman Software
                    Organizationでは、1500件を超える記事を公開。フロントからインフラまで幅広いカテゴリに投稿中。
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://github.com/inflion/"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="inflion"
                  className="w-20 rounded-md border-2 border-gray-300"
                  src="/inflion.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">inflion</h4>
                  <p className="text-gray-800 mt-2 text-sm">OSS活動</p>
                </div>
              </div>
            </a>
            <a
              href="https://github.com/goodby/"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="goodby"
                  className="w-20 rounded-md border-2 border-gray-300"
                  src="/goodby.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">goodby</h4>
                  <p className="text-gray-800 mt-2 text-sm">OSS活動</p>
                </div>
              </div>
            </a>
            <a
              href="https://github.com/shouldbee/"
              target="_blank"
              rel="norefferrer"
            >
              <div className="flex items-center p-4 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
                <img
                  alt="goodby"
                  className="w-20 rounded-md border-2 border-gray-300"
                  src="/shouldbee.png"
                />
                <div className="flex flex-col ml-5">
                  <h4 className="text-xl font-semibold my-0">shouldbee</h4>
                  <p className="text-gray-800 mt-2 text-sm">OSS活動</p>
                </div>
              </div>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
}
