import React from "react";
import styles from "../styles/Footer.module.css";

export default function Footer () {
  return (
    <footer className={styles.wrap}>
      <div className={styles.inner}>
        <a href="https://craftsman-software.com/">
          <p className={styles.logo}>
            <img src="/logo_white.svg" alt="Craftsman Software" />
          </p>
        </a>
      </div>
    </footer>
  );
};